.MainMenu {
  border-bottom: 5px solid var(--secondary-color);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  top: 0px;
  position: absolute;
  width: 100%;
  padding-left: 150px;
}

.CustomerNameContainer {
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: flex-end;
}
.CustomerName {
  padding-right: 20px;
}

/* https://codepen.io/ejsado/pen/gPVgVv */
input[name="css-tabs"] {
  display: none;
}

#tabs {
  padding: 0 0 0 50px;
  float: left;
  height: 80px;

}

#tabs::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -100;
  width: 100%;
  left: 0;
  margin-top: 0px;
  height: 80px;
  background: var(--primary-color);
}

#tabs::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  height: 80px;
  width: 97px;
  background: var(--secondary-color);
  transition: transform 400ms;
}

#tabs label {
  position: relative;
  z-index: 100;
  display: block;
  float: left;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  width: 100px;
  height: 100%;
  border-right: 1px dotted #575654;
  cursor: pointer;
  /*color: unset;*/
  color: black;

  background: var(--secondary-color);
}

#tabs label:first-child {
  border-left: 1px dotted #575654;
}

#tabs label::before {
  content: "";
  display: block;
  height: 30px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  margin: 10px auto;
}


#tab1::before {
  background-image: url('/public/icons/newOrder.svg');
}

#tab2::before {
  background-image: url('/public/icons/orderList.svg');
}

#tab3::before {
  background-image: url('/public/icons/message.svg');
}

#tab4::before {
  background-image: url('/public/icons/settings.svg');
}

#authorOrderList~#tabs #tab1::before,
#mediaProcessorList~#tabs #tab1::before {
  background-image: url('/public/icons/orderList.svg');
}

#newOrder:checked~#tabs #tab1::before,
#orderList:checked~#tabs #tab2::before,
#messages:checked~#tabs #tab3::before,
#settings:checked~#tabs #tab4::before {
  /*filter: invert(100%);*/

}

#authorOrderList~#tabs #tab1 {
  font-weight: bold;
  background: #FFF;
  filter: brightness(0.9)
}

#newOrder~#tabs #tab1,
#orderList~#tabs #tab2,
#messages~#tabs #tab3,
#settings~#tabs #tab4 {
  font-weight: bold;
  background: var(--secondary-color);
  filter: brightness(0.9)
}

#authorOrderList:checked~#tabs #tab1 {
  font-weight: bold;
  background: #FFF;
  filter: brightness(1)
}

#newOrder:checked~#tabs #tab1,
#orderList:checked~#tabs #tab2,
#messages:checked~#tabs #tab3,
#settings:checked~#tabs #tab4 {
  font-weight: bold;
  background: var(--secondary-color);
  filter: brightness(1)
}

#authorOrderList:checked~#tabs::after {
  transform: translateX(0);
}

#newOrder:checked~#tabs::after {
  transform: translateX(0);
}

#orderList:checked~#tabs::after {
  transform: translateX(101px);
}

#messages:checked~#tabs::after {
  transform: translateX(202px);
}

#subcontractorMessages:checked~#tabs::after {
  transform: translateX(101px);
}

#settings:checked~#tabs::after {
  transform: translateX(303px);
}

.messageCount {
  background-color: red;
    color: white;
    border-radius: 50%;
    display: none;
    padding: 4px 0px;
    position: relative;
    width: 20px;
    height: 20px;
    top: -60px;
    left: 57px;
}


@media screen and (max-width: 720px) {
  .MainMenu {
    padding-left: 0px;
  }
  #tabs {
    padding-left: 0px;
  }

  #tabs label {
    width: 90px;
  }

  .CustomerNameContainer {
    display: none;
  }
}