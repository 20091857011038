
.orderInfo .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.orderInfo .Heading {
  color:#58595e;
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
}

.orderInfo .item {
  display: flex;
  padding-bottom: 15px;
}

.orderInfo .itemLabel {
    font-weight: bold;
    width: 210px;
}


.orderInfo .itemLabelText {
  font-weight: bold;
  width: 240px;
}

.orderInfo .itemDataText {
  width: 71%;
}

.orderInfo .itemCopyText {
  width: 50px;
  text-align: end;
  cursor: pointer;
}

.orderInfo .itemDataProductContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.orderInfo .itemDataProduct {
    display: block;
}

.orderInfo .note {
  width: 100%;
  height: 100%;
}

.orderInfo .itemData {
  width: 50%;
}

.orderInfo .mediaItem {
  width: 200px;
    height: 134px;

    cursor: pointer;
    background: #dfdfdf;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
}



.orderInfo .playIcon {
  position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    color: #ffffffba;
    filter: drop-shadow(2px 4px 6px black);
    font-size: 59px;
}

.orderInfo .mediaItem img {
  
  width: 100%;
  height: 100%;
  object-fit: contain;
  
}

.orderInfo .mediaContainer {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.orderInfo .mediaDescription {
  width: 100%;
    height: 20px;
    background: black;
    color: white;
    opacity: 0.7;
    text-align: center;
    font-size: 12px;
    padding-top: 3px;
    position: relative;
    top: -30px;
}

.textAreaText {
  field-sizing: content;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  width:100%;
  font-family: Lato;
  font-size: 16px;
  padding:10px;
}

.MuiMenuItem-root {
 background-color: white !important;
}