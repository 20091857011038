
.orderInfo .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

/*
.MuiButtonBase-root {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}
  */

.orderInfo .Heading {
  color:#58595e;
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
}

.orderInfo .item {
  display: flex;
  padding-bottom: 15px;
}

.orderInfo .itemLabel {
    font-weight: bold;
    width: 210px;
}

.orderInfo .itemDataProductContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.orderInfo .itemDataProduct {
    display: block;
}

.orderInfo .note {
  width: 100%;
  height: 100%;
}

.orderInfo .itemData {
  width: 50%;
}

.orderInfo .mediaItem {
  width: 200px;
    height: 134px;
    cursor: pointer;
    background: #dfdfdf;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
}

.mediaProcessorViewMediaItem {
    width: 200px;
    cursor: pointer;
    background: #dfdfdf;
    border-radius: 3px;
    position: relative;
    margin-left: 20px;
    margin-bottom: 20px;
}

.mediaText {
  max-width: 132px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.mediaItemThumbnailContainer {
  align-items: center;
  background: #e1e1e1;
  display: flex;
  height: 80%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  max-height: 132px;
}

.orderInfo .mediaProcessorViewMediaItem img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.orderMediaListVideoPlayIconContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 69%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.orderMediaListVideoPlayIcon {
  color: white;
  width: 50%;
  height: 50%;
  color: #ffffff8f;
  width: 50%;
  height: 50%;
  filter: drop-shadow(2px 4px 6px black);
}


.orderInfo .playIcon {
  position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    color: #ffffffba;
    filter: drop-shadow(2px 4px 6px black);
    font-size: 59px;
}

.orderInfo .mediaItem img {
  
  width: 100%;
  height: 100%;
  object-fit: contain;
  
}

.orderInfo .mediaContainer {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.orderInfo .mediaDescription {
  width: 100%;
    height: 20px;
    background: black;
    color: white;
    opacity: 0.7;
    text-align: center;
    font-size: 12px;
    padding-top: 3px;
    position: relative;
    top: -30px;
}


.orderInfo .messageItemsHeading {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: bold;
  border-bottom: 1px solid #c6c6c6;
  padding: 10px;
  padding-left: 20px;
    padding-right: 20px;
}

.orderInfo .messageItem {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #c6c6c6;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.orderInfo .messageItem:hover {
  background: #f5f5f5;

}

.orderInfo .messageItemUnread {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
 /* font-weight: bold; */
  border-bottom: 1px solid #c6c6c6;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.orderInfo .messageItemUnread:hover {
  background: #f5f5f5;

}

.messageReplyIcon {
  width: 30px;
}
.messageDate {
  width: 140px;
}
.messageSubject {
  width: 310px;
}

.messageSubjectView {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 310px;
}

.MuiButton-contained {
  background-color: #ffffff !important;
  color: #484848 !important;
}

.MuiButton-outlined {
  border-color: black !important;
  color: black !important;
}

.SelectFileCheckboxContainer{
  position: absolute;
  top: 4px;
}

.SelectFileCheckboxContainer input{
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.deleteModalContainer {
  display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-end;
    gap: 25px;
  
}

.autocom {
  font-size: 12px !important;
}

.autoComplete-text {
  font-size: 12px !important;
}