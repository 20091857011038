.messageViewContainer {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
   
}

.messageViewTop {
    width: 100%;
}

.messageViewTop tr {
    font-size: 14px;
    border-bottom: 1px solid #e3e3e3;
    height: 30px;
}

.messageViewTop td {
    border-bottom: 1px solid #e3e3e3;
}

.messageViewTopTitle {
    width: 100px;
}

.messageOther {
    display: flex  ;
    width:100%;
    justify-content: flex-start;
}
.messageOtherContainer {
    margin: 20px;
    max-width: 60%;
}

.messageOtherText {
    background-color: #e3e3e3;
    padding: 20px;
    border-radius: 10px 10px 10px 10px;
}

.messageOtherFromAndDate {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.messageOtherFrom {
    color: gray;
    font-weight: bold;
    font-size: 12px;
    padding-right: 10px;
}

.messageOtherDate {
    color: gray;
    font-size: 12px;
}



.messageUser {
    display: flex  ;
    width:100%;
    justify-content: flex-end;
}



.messageUserContainer {
    margin: 20px;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.messageUserText {
    background-color: #4d4dff;
    color: white;
    padding: 20px;
    border-radius: 10px 10px 10px 10px;
}


.messageUserFromAndDate {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.messageUserFrom {
    color: grey;
    font-weight: bold;
    font-size: 12px;
    padding-right: 10px;
}

.messageUserDate {
    color: grey;
    font-size: 12px;
}

.messageViewContent {
    max-height: calc(100vh - 480px);
    height:100%;
    padding: 10px;
    overflow-y: auto;       /* Allows it to grow and scroll if needed */
    flex-grow: 1;   
}


.messageViewTextBarContainer {

    padding-top: 10px;
        /* Optional: restricts maximum height */
    height:50px;
}


/*scroll bar*/
/*
.scrollbar:: -webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #0009;
}
*/

.scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #0000;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
  }

  .messageViewTopRow {
    height: 40px;
    
  }