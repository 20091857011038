
.messageListContainer {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.createButton {
 
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
 
    height:26px;
}

th {
    color: #7a7a7a;
    font-size: 12px;
    border-bottom: 1px solid #e3e3e3;
}

.messageHeadingSubject {
    text-align: left;
    width:110px;
}

.messageHeadingCreatedAt {
    text-align: right;
    width: 80px
}

.messageHeadingCaseNo {
    text-align: left;
    width:140px;
}


.messageTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

tr {
    height: 40px;
    border-bottom: 1px solid #e3e3e3;
    cursor: pointer;
}

.messageTableContainer {
    height: calc(100vh - 455px);
    overflow: auto;
}

.messageSubject {
    text-overflow: ellipsis;
    max-width: 135px;
    overflow: hidden;
    white-space: nowrap;
    text-wrap-mode: nowrap;
    vertical-align: top;
}

.messageCaseNo {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width:135px;
    text-wrap-mode: nowrap;
}

.messageCreatedAt {
    text-align: end;
    padding-right: '5px';
    width: 80px;
    vertical-align: top;
}

.switch span {
    font-size: 14px;
}

.messageListContainerTop {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 10px;
    align-content: center;
    flex-wrap: nowrap;
}