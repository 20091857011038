.planningCalendar {
    display: flex;
    justify-content: center;
    color: #58595e;
    
}

.allTimeSlots {
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}

.timeBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%; /*320px;*/
    height: 70px;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;  
    margin-bottom: 10px;
    justify-content: space-around;
}

.timeBox:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%; /*320px;*/
    height: 70px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    justify-content: space-around;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textHeading {
    font-size: 16px;
}

.textBold {
    font-size: 14px;
    font-weight: bold;
}

.textNomal {
    font-size: 14px;
    font-weight: normal;
}

.textItalic {
    font-size: 14px;
    font-style: italic;
}

.spaceTop {
    margin-top: 30px;
}

.popover {
    max-width: 300px;
    padding: 10px;
    font-size: 12px;
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: #000 #0000;
    animation: l1 1s infinite;
  }
  @keyframes l1 {to{transform: rotate(.5turn)}}