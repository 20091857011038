
.newOrder {
}

.newOrder .logo {
  text-align: center;
}

.logo {
  background-image: var(--logoUrl);
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.newOrder .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.newOrder .Heading {
  color: #58595e;
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
}

.newOrder .stepper {
  margin-bottom: 40px;
}

.newOrder .line {
  width: calc(50% - 125px);
  background-color: #58595e;
  height: 2px;
  float: left;
  margin-top: 13px;
}

.newOrder .subheadingContainer {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}

.newOrder .subheading {
  color: #ffffff;
  height: 30px;
  background-color: #58595e;
  border-radius: 4px;
  opacity: 1;
  line-height: 30px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 208px;
  float: left;
}

.productItem {
  float: left;
  width: 100%;
 /* height: 97px; */
  cursor: pointer;
}

.productItem:hover {
  /*background-color: var(--secondary-color);*/
}

.productItemRadio {
  float: left;
  margin-top: 25px;
  left: 18px;
}

.productItemImage {
  width: 160px;
  float: left;
}

@media screen and (max-width: 960px) {

 
}

@media screen and (max-width: 760px) {
  .productItemImage {
    display: none;
  }

  .stepperContainer {
    display: none;
  }

  .productItemInfo {
    width: 200px !important;
    float: left;
  }

  .subproductItemInfo{
    width: 300px !important;
  }
}




.productItemImage img {
  margin-left: 30px;
  margin-top: 10px;
  height: 73px;
  width: 108px;
}

.defaultProductImage {
    margin-left: 30px;
    margin-top: 10px;
    height: 73px;
    width: 108px;
    background-color: rgb(219 219 219);
    text-align: center;
    padding-top: 23px;
  }

.productItemInfo {
  width: 245px;
  float: left;
}




.productItemInfo .productItemInfoName {
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
  margin-top: 10px;
}

.productItemInfo .productItemInfoDescrition {
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*-webkit-line-clamp: 2;*/
  -webkit-box-orient: vertical;
  outline: none;
  line-height: 17px;
}

.subProductItem {
  float: left;
  width: 100%;
 /* height: 97px; */
  cursor: pointer;
}

.subProductItem:hover {
  /*background-color: var(--secondary-color);*/
}

.subProductItemCheckbox {
  width: 70px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.subProductItemCheckbox span {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
    right: -2px;
}

.subProductItemImage {
  width: 160px;
  float: left;
}

.subProductItem img {
  margin-left: 30px;
  margin-top: 10px;
  -webkit-filter: drop-shadow(5px 5px 10px #58768953);
  filter: drop-shadow(5px 5px 10px #58768953);
  height: 73px;
}

.subProductItem {
  width: 450px;
  /* float: left; */
  display: flex;
}

.subproductItemInfo {

  margin-left: 20px;
}

.subproductItemInfo .subproductItemInfoName {
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
 /* margin-top: 10px; */
}

.subproductItemInfo .subproductItemInfoDescrition {
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  outline: none;
  line-height: 17px;
  max-width: 350px;
}

.productItemPrice {
  text-align: right;
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
  margin-top: 10px;
  margin-right: 20px;
}

.productItemPriceExTax {
  text-align: right;
  font: normal normal normal 10px/8px Roboto;
  letter-spacing: 0px;
  color: #58595e;

}

.subproductItemPriceExTax {
  text-align: right;
  font: normal normal normal 10px/8px Roboto;
  letter-spacing: 0px;
  color: #5b5a5a;
}

.button {
  width: 150px;
  height: 35px;
}

.addonContainer {
  background-color: rgb(219 219 219);
  width: 100%;
  padding: 30px;
  margin-top: 30px;
}

.addonHeading {
  text-align: left;
  font: normal normal bold 16px / 21px Roboto;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 26px;
}

.photographyCompanyContactInfo {
  color: var(--text-color);
  height: 100px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 70px;
}

.productCategoryTitle {
  font-size: 32px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  font-weight: bold;
}

.buyingTermsContainer {
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
}

.buyingTermsLink{
  color: rgba(0, 0, 0);;
  text-decoration: underline;
}
.buyingTermsLink:hover{
  color: rgba(0, 0, 0);;
  text-decoration: none;
}

.buyingTermsWarning {
  border: 1px solid red;
  max-width: 340px;
}
