.messageViewTextBar {
   /* position: absolute;
    bottom: 20px;*/
    width: calc(100% - 40px);
    padding: 10px;
    min-height: 50px;
}

.messageViewTextBarContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 100%;
    position: relative
}

.messageViewTextBarInputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1005;
}

.messageViewTextBarInputSmiles {
    background: #e3e3e3;
    border-radius: 0 10px 10px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.messageViewTextBarInput {
    background: #e3e3e3;
    border-color: #e3e3e3;
    color: #333333;
    border-radius: 10px 0 0 10px;
    min-height: 40px;
    padding-left: 10px;
    width: 100%;
    padding-top: 10px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.messageViewTextBarSend {
    padding-left: 20px;
    padding-bottom: 10px;
    cursor: pointer;
}

.messageViewTextBarImageIcon {
    padding-right: 20px;
    padding-bottom: 10px;
    cursor: pointer;
}

.selectedMediaContainerImage {
    width: 100%;
    position: relative;
}

textarea:focus {
    outline: none;
}

.emojiPickerContainer {
    position: absolute;
    bottom: 50px;
    right: 70px;
    z-index: 1010;
}

.messageViewTextBarImageContainer {
    border-radius: 10px 10px 0 0;
    background: #e3e3e3;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
}

.selectedMediaContainer {
    width: 180px;
    max-height: 100%;
    border-radius: 10px 10px 0 0;
    padding: 10px;
    position: relative;
    cursor: pointer;
}

.selectedMediaContainer img {
    width: 100%;
    border-radius: 5px;

}

.selectedMediaButtonContainer {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: #e3e3e3;
    width: 30px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    gap: 5px;
    z-index: 1001;
    
}

.selectedMediaButton {
    cursor: pointer;
}

.mediaOverlay {
    position: absolute;
    z-index: 1000;
    width: calc(100% - 20px);
    top: 10px;
    cursor: pointer;
}

.mediaOverlay svg {
    width: 100%;
    border-radius: 5px;
    height: 100%;
    cursor: pointer;
}

.messageViewTextBarInline {
    width: 100%;
    min-height: 50px;
}

.messageViewTextBarContainerInline {
    padding-top: 10px; 
    height: 100% !important;
    display:flex;
    flex-direction: row;
    align-items: flex-end;
}