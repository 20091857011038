
.orderInfo .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}


/*
.MuiButtonBase-root {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}
  */

.orderInfo .Heading {
  color:#58595e;
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
}

.orderInfo .item {
  display: flex;
  padding-bottom: 15px;
}

.orderInfo .itemLabel {
    font-weight: bold;
    width: 210px;
}

.orderInfo .itemDataProductContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.orderInfo .itemDataProduct {
    display: block;
}

.orderInfo .note {
  width: 100%;
  height: 100%;
}

.orderInfo .itemData {
  width: 50%;
}

.orderInfo .mediaItem {
  width: 200px;
    height: 134px;

    cursor: pointer;
    background: #dfdfdf;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
}



.orderInfo .playIcon {
  position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    color: #ffffffba;
    filter: drop-shadow(2px 4px 6px black);
    font-size: 59px;
}

.orderInfo .mediaItem img {
  
  width: 100%;
  height: 100%;
  object-fit: contain;
  
}

.orderInfo .mediaContainer {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.orderInfo .mediaDescription {
  width: 100%;
    height: 20px;
    background: black;
    color: white;
    opacity: 0.7;
    text-align: center;
    font-size: 12px;
    padding-top: 3px;
    position: relative;
    top: -30px;
}

.textAreaText {
  field-sizing: content;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  width:100%;
  font-family: Lato;
  font-size: 16px;
  padding:10px;
}

.backButton {
  position: absolute;
  font-size: 14px;
  color: #7a7a7a;
  cursor: pointer;
  padding-bottom: 20px;
  top: 170px;
}

.FilterBar {
  display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      margin: 0px;
}