
.settings .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.settings .Heading {
  color:#58595e;
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
}

.settings .item {
  display: flex;
  padding-bottom: 15px;
}

.settings .itemLabel {
    font-weight: bold;
    width: 140px;
}

.settings .itemDataProductContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.settings .itemDataProduct {
    display: block;
}

.settings .note {
  width: 100%;
  height: 100%;
}

.settings .itemData {
  width: 50%;
}

.settings .signOutButton {
  margin-top: 5px;
  margin-right: 15px;
  width: 100%;
  cursor: pointer;
}