.messageViewEmptyImage {
    /*filter: grayscale(100%);*/
    max-width: 500px;
}   

.messageViewEmpty {
    width: 100%;
    height: 100%;
}

.messageViewEmptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    
}

.messageViewEmptyTextHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.messageViewEmptyTextNormal {
    font-size: 16px;
    color: #7a7a7a;
    text-align: center;
}

.mediaItem {
    margin-left: 20px;
    margin-bottom: 20px;
    position: relative;
    background: #e1e1e1;
    border-radius: 5px;
  }