

.error .background {
    background: url(/public/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(1);
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    filter: opacity(0.5);
  }
  
  .error .logo {
      position: absolute;
      right: 60px;
      top:60px;
      color:var(--brandColor);
      z-index: 2;
      font-size: 20px;
      font-weight: bold;
  }
  
  .errorContainer {
    z-index: 2;
    position: relative;
    text-align: center;
    
  }

  .errorMessage {
    color: #fff;
    font-size: 30px;
    background-color: black;
    opacity: 0.80;
    height:100px;
    padding-top: 32px;
    text-align: center;
  }
  
