
.orderList .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.orderList .Heading {
  color:#58595e;
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
}


.orderList .dateRangeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.orderList .dateRangeContainer .react-datepicker-wrapper input {
  padding: 5px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #919191 !important
}


.orderList select {
  padding: 5px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  width:159px;
}