
.subProductItemAmountContainer {
    display: flex;
    gap:2px;
    width: 70px;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 3px;
  }
  
  .subProductItemAmountMinus {
    cursor: pointer;
    font-size: 20px;
    border: 2px solid rgba(0, 0, 0, 0.6); 
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 3px;
    font-size: 15px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  
  .subProductItemAmountPlus {
    cursor: pointer;
    font-size: 20px;
    border: 2px solid rgba(0, 0, 0, 0.6); 
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 3px;
    font-size: 15px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  
  .subProductItemAmount {
    border: 2px solid rgba(0, 0, 0, 0.6); 
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 3px;
    font-size: 12px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    display: flex;
    align-items: center;
    justify-content: center;
  }