.messageViewEmptyImage {
    /*filter: grayscale(100%);*/
    max-width: 500px;
    background-image: url('/public/empty_thread.jpg');
    height: 240px;
    background-size: contain;
    background-repeat: no-repeat;
}   

.messageViewEmpty {
    width: 100%;
    height: 100%;
}

.messageViewEmptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    
}

.messageViewEmptyTextHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.messageViewEmptyTextNormal {
    font-size: 16px;
    color: #7a7a7a;
    text-align: center;
}
