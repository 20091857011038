.forgotPassword .logo {
  background-image: var(--logoUrl);
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.forgotPassword .background {
  /*
  background: url(/public/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(1);
  */
  background-color: var(--primary-color);

  height: 100vh;
  position: absolute;
  z-index: 1;
}

.warning {
  color: red;
}

.forgotPassword .container {
  z-index: 2;
  position: relative;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: nowrap;
}

.forgotPassword .buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
}

.forgotPassword .button {
  display: inline-flex;
  width: 200px;
  height: 35px;
  
}

.forgotPassword .container h1 {
  color: black;
  font-size: 31px;
  line-height: 54px;
  margin: 0px;
}

.forgotPassword .container .SubHeadingContainer {
  padding-top: 30px;
  max-width: 680px;
  margin-right: 40px;
}

.forgotPassword .container .HeadingContainer {
  max-width: 600px;
}

.forgotPassword .container .boxContainer {
  background: #fff;
  border-radius: 10px;
  width: 600px;
}

.forgotPassword .container .boxContainer .textContainer {
  padding: 30px;
}

