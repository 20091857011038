.markerjs-container {
    display: relative;
    padding-top:40px;
}

.markerjs-container-view {
    position: relative;
    padding-top:40px;
    height: 600px;
}

.markerjs-container-view-image {
    position: absolute;
    top: 0px;
    object-fit: contain;
    width: 900px;
    height: 600px;
}

.markerjs-container-view-overlay {
    position: absolute;
    top: 0;
    left: 0px;
}

.markerjs-container-view-overlay > svg {
    position: absolute;
    top: 0;
    object-fit: contain;
    width: 900px;
    height: 600px;
}

.MarkerBottomContainer {
    z-index: 10000;
    position: relative;
}