
.categorySelector .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
}

.categorySelector .categoriesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}


.categorySelector .Heading {
  color:#58595e;
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
}

.categorySelector .categoryImageContainer {
  height: 194px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categorySelector .categoryImage {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
}

.categorySelector .MuiPaper-root {
  background-color: transparent !important;
}

.categorySelector .categoryCard {
  background: #fff;  
  transition: background-color 0.5s ease;
}

.categorySelector .categoryCard:hover {
  background: #eeeeee;  
}