.sentToSelector {

    width: 100%;
}


.fieldLabel {
    min-width: 90px;
}

.fieldSelectedData {
    min-width: 140px;
}

.BottomContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    gap:10px;
}


.base-Popper-root {
    z-index: 10000 !important;
    background: white !important;
}